.custom_id_content {
  width: 65%;
  margin: 110px auto 40px;
}

.custom_id_content h2 {
  font-weight: 700;
  color: #f5813c;
}

.custom_pendant_img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 46px;
}

.custom_pendant_img > img {
  width: 345px;
  height: 341px;
}

.pendant_text_mandatory {
  position: absolute;
}
.pendant_text_all {
  position: absolute;
}

.pendant_text_mandatory .pendant_text_primary {
  position: absolute;
  font-weight: 600;
  font-size: 14px;
  width: 160px;
  text-align: center;
  transform: translate(-148px, 22px);
}
.pendant_text_mandatory .pendant_text_secondary {
  position: absolute;
  font-weight: 600;
  font-size: 14px;
  width: 160px;
  text-align: center;
  transform: translate(-148px, 52px);
}
.pendant_text_mandatory .pendant_text_third {
  display: none;
}
.pendant_text_all .pendant_text_primary {
  position: absolute;
  font-weight: 600;
  font-size: 11px;
  width: 160px;
  text-align: center;
  /* transform: translate(-155px, 40px); */
  transform: translate(-146px, 22px);
}
.pendant_text_all .pendant_text_secondary {
  position: absolute;
  font-weight: 600;
  width: 160px;
  font-size: 11px;
  /* transform: translate(-146px, 68px); */
  transform: translate(-146px, 44px);
  text-align: center;
}
.pendant_text_all .pendant_text_third {
  position: absolute;
  font-weight: 600;
  font-size: 11px;
  width: 160px;
  text-align: center;
  /* transform: translate(-146px, 19px); */
  transform: translate(-146px, 66px);
}

.only_first {
  position: absolute;
  font-weight: 600;
  font-size: 16px;
  width: 160px;
  text-align: center;
  /* transform: translate(-155px, 40px); */
  transform: translate(-146px, 40px);
}

.pendant_front_view {
  position: absolute;
  color: #777;
  transform: translate(-10rem, 10rem);
}

.pendant_back_view {
  position: absolute;
  color: #777;
  transform: translate(7rem, 10rem);
}

.custom_id_shipping_btn {
  width: 100%;
  color: #f5813c;
  background: #ffffff;
  border: 2px solid #f5813c;
  border-radius: 10px;
  padding: 21px;
  margin: 28px 0 8px;
}

.contactus_form {
  margin-top: 50px;
}

.customid_form .form-control {
  padding: 0.375rem 1.5rem !important;
}

.customid_formgroup {
  position: relative;
  margin: 0 !important;
}

.customid_formgroup-input {
  text-align: -webkit-right;
  text-align: right;
  /* direction: rtl; */
  appearance: none;
  font-size: 14px;
  color: #295E65;
  font-weight: bold;
}

.customid_formgroup-input-RTL {
  direction: rtl;
}
#name {
  height: 45px;
  border-color: #e5e5e5;
  border-radius: 10px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom: 0px solid #e5e5e5 !important;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
}

#uniqueid {
  height: 45px;
  border-top: none;
  border-color: #e5e5e5;
  border-radius: 0 !important;
  border-bottom: 0px solid #e5e5e5 !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

#abc {
  height: 45px;
  border-top: none;
  border-color: #e5e5e5;
  border-radius: 10px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

#name::placeholder,
#uniqueid::placeholder,
#abc::placeholder {
  color: #acacac;
  font-size: 14px;
}

#quantity {
  height: 42px;
  border-radius: 10px;
  margin-top: 40px;
  border-color: #e5e5e5;
  border-radius: 10px;
  border-bottom: 0px solid #e5e5e5 !important;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
}

.customid_formgroup-label {
  position: absolute;
  top: 12px;
  left: 24px;
}

.customid_formgroup-label.quantity {
  top: 10px;
}
.customid_formgroup-label2.arrow {
  position: absolute;
  top: 19px;
  right: 10px;
}

.custom_id_shipping_btn:hover {
  background-color: #f5813c;
  border-color: #f5813c;
}

.custom_id_shipping_btn:hover svg path {
  fill: #ffffff;
}

.custom_id_shipping_btn svg {
  transform: rotate(270deg);
  float: right;
  margin: 8px 0;
}

.custom_id_shipping_btn svg path {
  fill: #f5813c;
}

.customid_form p {
  display: flex;
}

.customid_form p a {
  text-align: center;
  text-decoration: underline;
  color: black;
  padding-right: 8px;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .custom_id_content {
    width: 100%;
    margin: 32px auto 310px;
  }
}

.remove_focus:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
