.nav_bar {
    background-color: #000;
    height: 100px;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
}

.nav_bar img {
    margin-top: 30px;
    height: 44px;
    width: 288px;
}

.nav_bar .icons {
    margin-top: 50px;
}

.nav_bar .nav_icon {
    margin-right: 12px;
}

@media only screen and (max-width: 768px) {
    .nav_bar {
        height: 70px;
        padding-left: 0;
        padding-right: 0;
    }

    .nav_bar img {
        margin-top: 19px;
        height: 29px;
        width: 183px;
    }

    .nav_bar .col_icons {
        display: none;
    }
}