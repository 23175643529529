.followus_info {
  width: 55%; /*---------------------mayur-----------------------------------------------------------------------------*/
  margin-bottom: 0; /* -------------------mayur----------------------------------------------------------------------- */
}

.followus_info h2 {
  color: #f5813c;
  font-weight: 700;
  margin: 50px auto 56px;
}

.followus_info .icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.follow_insta img {
  width: 65.54px;
  height: 65.54px;
}

.follow_tiktok img {
  width: 56.21px;
  height: 65.55px;
}

.follow_linkedin img {
  width: 65.54px;
  height: 65.54px;
}

.follow_twitter img {
  width: 65.54px;
  height: 53.27px;
}

.follow_facebook img {
  width: 66.79px;
  height: 66.39px;
}

.follow_youtube img {
  width: 80.14px;
  height: 56.32px;
}

@media (max-width: 992px) {
  .followus_info {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .followus_info {
    width: 100%;
  }

  .followus_info h2 {
    font-weight: 700;
    margin-top: 40px;
  }

  .followus_info .icon > img {
    width: 30px;
  }

  .follow_youtube img {
    width: 37px;
    height: 26px;
  }

  .follow_tiktok img {
    width: 25.95px;
    height: 30.26px;
  }

  .follow_twitter img {
    width: 30.26px;
    height: 24.59px;
  }

  .follow_linkedin img {
    width: 30.26px;
    height: 30.26px;
  }

  .follow_insta img {
    width: 30.26px;
    height: 30.26px;
  }

  .follow_facebook img {
    width: 30.84px;
    height: 30.65px;
  }
}

/* ---------------------mayur-------------------------------------------------------------------------------------- */
.follow_get_app .get_app {
  margin: 103px auto 59px;
}

.follow_get_id .get_id {
  margin: 41px auto 110px;
}

@media (max-width: 768px) {
  .follow_get_app .get_app {
    margin: 51px auto 13px;
  }

  .follow_get_id .get_id {
    margin: 0 auto 15px;
  }
}
