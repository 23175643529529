.about_heading {
  width: 90%;
  margin-top: 100px;
}

.txt {
  color: #f5813c;
  font-weight: 700;
  font-size: 32px;
  letter-spacing: 0.02em;
}

.about_main_content {
  width: 90%;
}

.dog_img img {
  width: 367px !important;
  height: 365px;
}

.txt1 {
  font-size: 14px;
}

.txt6 {
  font-weight: 700;
}

.about_quote {
  width: 90%;
  margin-bottom: 50px; /*--------mayur----------------------------------------------------------------------*/
}

.about_quote .txt {
  padding: 0 6px;
}

@media only screen and (max-width: 768px) {
  .about_quote .txt {
    padding-left: 13px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .about_heading {
    width: 100%;
    margin-top: 16px;
  }

  .about_main_content {
    width: 100%;
  }

  .about_quote {
    width: 100%;
    margin-bottom: 0; /*----------------mayur------------------------------------------------------------------*/
  }

  .txt1 {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
}

@media only screen and (max-width: 575px) {
  .dog_img img {
    width: 268px !important;
    height: 265px;
  }
}

/* ---------------------mayur-------------------------------------------------------------------------------------- */
.about_get_app .get_app {
  margin: 36px auto 43px;
}

.about_get_id .get_id {
  margin: 41px auto 32px;
}

@media (max-width: 768px) {
  .about_get_app .get_app {
    margin: 37px auto 13px;
  }

  .about_get_id .get_id {
    margin: 0 auto 24px; 
  }
}