.invalid_content {
  width: 68%;
  margin-bottom: 150px;
}

.invalid_content > h2 {
  font-weight: 700;
  color: #f5813c;
  margin: 110px 0 60px;
}

.collapse_name {
  display: inline-flex;
  font-weight: 700;
  letter-spacing: 2%;
}

.invalid svg {
  float: right;
  margin-top: 8px;
}

.invalid svg path {
  fill: #acacac;
}

.is-closed > svg {
  transition: transform 0.3s ease-in-out;
}

.is-open > svg {
  transform: rotate(-180deg);
  transition: transform 0.3s ease-in-out;
}

.Collapsible__contentInner {
  margin: 16px 0 37px;
}

.profile_collapse_row_content {
  font-size: 14px;
}

/* ---------------------mayur---------------------------------------------------------------- */
.invalid_get_app .get_app {
  margin: 160px auto 64px;
}

.invalid_get_id .get_id {
  margin: 41px auto 110px;
}

@media (max-width: 912px) {
  .invalid_content {
    margin-bottom: 296px;
  }
}

@media (max-width: 820px) {
  .invalid_content {
    margin-bottom: -52px;
  }
}

@media (max-width: 768px) {
  .invalid_content {
    width: 100%;
    margin-bottom: 421px; /* ---------------------mayur------------------------------------------------------------- */
  }

  .invalid_content > h2 {
    font-weight: 700;
    color: #f5813c;
    margin: 24px 0 60px;
  }

  /* ---------------------mayur--------------------------------------------------------- */
  .invalid_get_app .get_app {
    margin: 290px auto 18px;
  }

  /* ---------------------mayur----------------------------------------------------------- */
  .invalid_get_id .get_id {
    margin: 0 auto 18px;
  }
}

@media (max-width: 486px) {
  .invalid_content svg {
    display: none;
  }

  .invalid_content {
    margin-bottom: 0px;
  }
}

@media (max-width: 280px) {
  .get_app {
    width: 100%;
    padding: 8px !important;
  }
  .get_id {
    padding: 8px !important;
  }
  .jellieid_info h2 {
    padding-top: 5 !important;
}
}
