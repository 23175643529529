.thankyou_content {
  width: 68%;
  margin: 147px auto 277px;
}

.thankyou_content h2 {
  font-weight: 700;
  color: #f5813c;
}

.thankyou_content p {
  font-weight: 700;
  margin: 20px 0 58px;
}

.return_home_button {
  width: 100%;
  color: #f5813c;
  background: #ffffff;
  border: 2px solid #f5813c;
  border-radius: 10px;
}

.return_home_button:hover {
  background-color: #f5813c;
  border-color: #f5813c;
}

.return_home_button:hover svg path {
  fill: #ffffff;
}

.return_home_button svg {
  transform: rotate(270deg);
  float: right;
  margin: 8px 0;
}

.return_home_button svg path {
  fill: #f5813c;
}

@media only screen and (max-width: 912px) {
  .thankyou_content {
    margin-bottom: 589px;
  }
}
@media only screen and (max-width: 820px) {
  .thankyou_content {
    margin-bottom: 401px;
  }
}
@media only screen and (max-width: 768px) {
  .thankyou_content {
    margin-bottom: 353px;
  }
}
@media only screen and (max-width: 575px) {
  .thankyou_content {
    width: 100%;
    margin: 32px auto 301px;
  }

  .thankyou_content h2 {
    padding-right: 56px;
  }
}
@media only screen and (max-width: 486px) {
  .thankyou_content {
    margin-bottom: 320px;
  }
}
@media only screen and (max-width: 396px) {
  .thankyou_content {
    margin-bottom: 256px;
  }
}