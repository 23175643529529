.footer {
  background-color: black;
  color: #fff;
}

.footer .container {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer_links,
.footer_links_sm {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
}

.footer_links_sm {
  display: none;
}

.footer_links li {
  margin-right: 80px;
}

.footer_links li a,
.footer_links_sm li a {
  display: block;
  color: #fff;
  text-decoration: none;
  height: 25px;
}

.right_reserved_quote {
  margin: 0 !important;
  font-size: 14px;
  color: #acacac;
}

@media (max-width: 767px) {
  .footer .container {
    padding: 14px 28px !important;
  }
  .footer_links {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer_links li {
    margin-right: 0;
  }

  .footer_links li:nth-child(4) {
    display: none;
  }

  .footer_links_sm {
    display: block;
  }

  .footer_links_sm li {
    margin-right: 40px;
  }

  .right_reserved_quote {
    width: 64px;
    text-align: center;
  }
}
