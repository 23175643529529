body {
  font-family: Helvetica, sans-serif;
}

.getapp_wrapper {
  position: relative;
}

.getapp_wrapper::after {
  content: "";
  position: absolute;
  background: linear-gradient(to bottom, #f5813c00, #f5813c);
  bottom: 0;
  left: 0;
  right: 0;
  background-size: 100%;
  height: 555px;
  padding-bottom: 115px;
  z-index: -1;
}

@media (max-width: 768px) {
  .getapp_wrapper::after {
    height: 648px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}