.get_app {
  width: 90%; /* -----------------mayur-------------------------------------------------------------------*/
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 5px solid #f5813c;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  height: 140px;
}

.get_app > div {
  display: flex;
  align-items: center;
}

.get_app > div:nth-child(1) {
  width: 50%;
  justify-content: flex-start;
}

.get_app > div:nth-child(2) {
  width: 40%;
  justify-content: space-between;
}

.jelle_appicon {
  width: 88px;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  margin-right: 15px;
}

.get_app h2 {
  font-weight: 700;
}

.get_app h2 > span.download {
  display: none;
}

.jellie_qrcode {
  width: 72.5px;
  height: 72.5px;
}

.jellie_qrcode img {
  width: 100%;
  height: auto;
}

.get_app > div > .scan {
  width: 28%;
  font-size: 14px;
  font-weight: 300;
}

.download_android_ios {
  width: 132px;
  height: 99px;
}

.download_android_ios img {
  width: 100%;
  height: auto;
}

.get_id {
  width: 90%; /* -----------------mayur------------------------------------------------------------------*/
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 10px;
  border: 5px solid #f5813c;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  height: 300px;
  color: #000;
}

.paw_icon > div {
  width: 66.5px;
  height: 66.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  border: 1.09997px solid #e5e5e5;
  box-shadow: 2px 4.3999px 4.3999px rgba(0, 0, 0, 0.25);
  border-radius: 8.7998px;
  transform: rotate(45deg);
  margin: 18px 12px 12px;
}

.paw_icon > div > img {
  width: 45.4px;
  height: 36.1px;
  transform: rotate(-45deg);
}

.jellieid_info h2 {
  font-weight: 700;
}

.info_sm {
  display: none;
}

.jellieid_info .info_lg,
.jellieid_info ul li {
  font-size: 14px;
}

.jellieid_info ul {
  list-style: none;
  padding: 0;
}

.jellieid_info {
  width: 100%;
}

.jellieid_keychain > img {
  width: 261px;
  height: 258px;
}

@media (max-width: 992px) {
  /* .get_app_id.container {
    max-width: 100%;
  } */

  .jellie_qrcode img {
    display: none;
  }

  .get_app > div > .scan {
    display: none;
  }

  .jelle_appicon {
    width: 130px;
    height: 87.68px;
  }

  .jellieid_keychain > img {
    width: 220px;
    height: 210px;
    margin-right: -40px;
    margin-top: 15px;
  }

  .jellieid_info h2 {
    margin-top: -30px;
    font-size: 30px;
    margin-bottom: -10px;
  }

  .paw_icon > div {
    margin-top: 0px;
    margin-left: 0px;
  }
}

/* @media (min-width: 768px) {
  .get_app > div {
    width: auto !important;
  }

  .info_sm {
    display: none;
  }

  .jellieid_info {
    width: 50%;
  }
} */

@media (max-width: 768px) {
  .get_app {
    height: 100px;
  }

  .followus_wrapper {
    padding-bottom: 20px;
  }

  .followus_info {
    width: 100%;
  }

  .followus_info .icon > img {
    width: 37px;
  }

  .info_sm {
    display: block;
    font-size: 14px;
  }

  .info_lg,
  .jellieid_info ul {
    display: none;
  }

  .get_app {
    width: 100%;
    padding: 8px 1rem 8px 1.5rem !important;
  }

  .get_app > div:nth-child(2) {
    width: auto;
  }

  .get_app h2 {
    font-size: 16px;
  }

  .jelle_appicon,
  .get,
  .jellie_qrcode,
  .scan {
    display: none;
  }

  .get_app h2 > span.download {
    display: inline-block;
    margin-top: 10px;
  }

  .download_android_ios {
    width: 123px;
    height: 95px;
  }

  .download_android_ios img {
    /* width: 100%;
    height: 100%; */
    margin-top: 6px;
    height: 85px;
  }

  .get_id {
    width: 100%;
    padding: 8px 1rem 8px 1.5rem !important;
    height: 125px;
  }

  .paw_icon {
    display: none;
  }

  .jellieid_info {
    width: 60%;
  }

  .jellieid_info {
    padding: 0 !important;
  }

  .jellieid_info h2 {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 0 !important;
    margin-top: 0;
    margin-bottom: 7px;
  }

  /* .jellieid_info h2 {
    margin-top: -30px;
    font-size: 30px;
  } */

  .jellieid_keychain {
    padding: 0 !important;
  }

  .jellieid_keychain > img {
    width: 100.9px;
    height: 100px;
    margin-right: 0px;
    margin-top: 0px;
  }
}

/* @media (max-width: 320px) {

  .followus .get_app {
    padding: 8px !important;
  }

  .get_app h2 {
    font-size: 14px;
  }

  .download_android_ios {
    width: 90px;
    height: 65px;
  }

  .get_id {
    padding: 8px !important;
  }

  .jellieid_info h2 {
    font-size: 12px;
  }

  .info_sm {
    display: block;
    font-size: 12px;
  }

  .jellieid_keychain>img {
    width: 90.9px;
    height: 90px;
    margin-top: 10px;
  }
} */
