.landing_top {
  height: 45px;
  margin: 0;
  padding: 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.landing_top .box_one {
  width: 115px;
  height: 45px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.landing_top .box_two {
  width: 180px;
  height: 45px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}

.landing_top .box_two p {
  text-align: center;
  margin-top: 10px;
}

.landing_top .box_two p img {
  /*----------mayur------------------------------------------------------------------*/
  width: 130.3px;
  height: auto;
}

.landing_top .box_three {
  width: 180px;
  height: 45px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.landing_logo {
  width: 100%;
  height: 626px;
}
.landing_logo2 {
  display: none;
}

.text_link {
  margin-top: 40px;
}

.text_link .text_great_pet h4 {
  font-size: 32px;
  font-weight: 700;
  color: #f5813c;
  letter-spacing: 0.02em;
  margin-top: 25px;
}

.text_link .download_link img {
  float: right;
  width: 156px;
  height: 104px;
}

.second_section {
  margin-top: 90px;
  text-align: center;
  margin-bottom: 200px;
}

.second_section .healthy_habit img {
  margin-top: 15px;
  width: 121.23px;
  height: 102.45px;
}

.second_section .right_food img {
  width: 170.07px;
  height: 127.08px;
}

.second_section .simplify_hosting img {
  width: 200.54px;
  height: 96px;
  margin-top: 17px;
}

.second_section .share_info img {
  width: 139.08px;
  height: 112.02px;
  margin-top: 10px;
}

.second_section .healthy_habit .typography {
  margin-top: 85px;
  padding-left: 25px;
}

.second_section .right_food .typography {
  margin-top: 75px;
  padding-left: 16px;
}

.second_section .simplify_hosting .typography {
  margin-top: 89px;
  padding-left: 8px;
}

.second_section .share_info .typography {
  margin-top: 80px;
  padding-left: 5px;
}

.second_section .typography {
  text-align: left;
}

.second_section .typography h6 {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.02em;
}

.second_section .typography p {
  color: #404040;
  font-size: 16px;
  letter-spacing: 0.02em;
  margin-top: 25px;
}

/* carousel */

.ssview {
  margin-bottom: 0; /*--------------mayur---------------------------------------------------*/
}

/* width */
.scroll-layout::-webkit-scrollbar {
  width: 3px;
  height: 10px;
}

/* Track */
.scroll-layout::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.scroll-layout::-webkit-scrollbar-thumb {
  background: #969696;
  border-radius: 10px;
}

.scroll-layout:hover {
  overflow: scroll;
}
.scroll-layout {
  overflow: hidden;
}

/* mobile */
.ssview1 {
  display: none;
}

.carousel-color {
  color: #f5813c;
}

@media only screen and (max-width: 768px) {
  .landing_top .box_one {
    display: none;
  }

  .landing_top .box_two {
    /* width: 50%; */ /*----------------------------mayur-------------------------------------------------*/
  }

  .landing_top .box_three {
    width: 50%;
  }

  .landing_logo {
    display: none;
  }
  .landing_logo2 {
    display: block;
    width: 100%;
    object-position: bottom;
    object-fit: cover;
    height: 394px;
  }

  .text_link {
    margin-top: -10px;
  }

  .text_link .text_great_pet h4 {
    text-align: center;
  }

  .text_link .download_link img {
    display: none;
  }

  .second_section {
    margin-top: 15px;
    margin-bottom: 50px;
  }

  .second_section .typography h6 {
    font-size: 16px;
  }

  .second_section .typography p {
    font-size: 14px;
    letter-spacing: 0.02em;
  }

  .second_section .healthy_habit img {
    margin-right: 27px;
  }

  .second_section .healthy_habit .typography {
    margin-top: 50px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .second_section .right_food img {
    margin-top: 40px;
    margin-left: 20px;
  }

  .second_section .right_food .typography {
    margin-top: 50px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .second_section .simplify_hosting img {
    margin-top: 40px;
    margin-right: 20px;
  }

  .second_section .simplify_hosting .typography {
    margin-top: 50px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .second_section .share_info img {
    margin-top: 40px;
    margin-left: 15px;
  }

  .second_section .share_info .typography {
    margin-top: 50px;
    padding-left: 8px;
    padding-right: 8px;
  }

  /* carousel */
  .ssview {
    display: none !important;
  }

  /* mobile */
  .ssview1 {
    display: block;
    margin-bottom: 0; /*----------mayur------------------------------------------------------------ */
  }
}

/* ---------------------mayur---------------------------------------------------------------------- */
.landing_get_app .get_app {
  margin: 36px auto 43px;
}

.landing_get_id .get_id {
  margin: 69px auto 103px;
}

@media (max-width: 768px) {
  .landing_get_app .get_app {
    margin: 20px auto;
  }

  .landing_get_id .get_id {
    margin: 0 auto 62px;
  }
}

@media only screen and (max-width: 576px) {
  .landing_top .box_two p img {
    margin-right: 10px;
  }
  .landing_logo2 {
    height: 276px;
  }
  /* .second_section .healthy_habit img,
  .second_section .right_food img,
  .second_section .simplify_hosting img,
  .second_section .share_info img {
    margin-right: 0;
    margin-left: 0;
  } */
}
