.jellieid_page {
  margin-top: 100px;
  padding-left: 196px;
  padding-right: 196px;
  margin-bottom: -50px;
}

.jellieid_page .head h2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: #f5813c;
  margin-bottom: 15px;
}

.jellieid_page .head p {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 0;
}

.jellieid_page .type {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.jellieid_page .type .sticker,
.jellieid_page .type .pendant {
  border: 1px solid #acacac;
  border-radius: 13.4432px;
  width: 257px;
  height: 390px;
  text-align: center;
}

.jellieid_page .type .sticker .first_para,
.jellieid_page .type .pendant .first_para {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-top: 15px;
}

.jellieid_page .type .sticker .sticker_img {
  width: 157px;
  height: 159px;
  margin-top: -10px;
}

.jellieid_page .type .sticker .small_sticker {
  width: 162px;
  height: 95px;
  margin-top: -15px;
}

.jellieid_page .type .sticker .second_para,
.jellieid_page .type .pendant .second_para {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-top: 10px;
}

.jellieid_page .type .sticker .third_para,
.jellieid_page .type .pendant .third_para {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #acacac;
  margin-top: 10px;
}

.jellieid_page .type .pendant .pendant_img {
  width: 209px;
  height: 206px;
  margin-top: 22px;
}

.jellieid_page .continue_btn {
  width: 100%;
  color: #f5813c;
  background: #ffffff;
  border: 2px solid #f5813c;
  border-radius: 10px;
  padding: 21px;
  margin: 35px 0 50px;
}

.jellieid_page .continue_btn:hover {
  background-color: #f5813c !important;
  border-color: #f5813c;
  color: #fff;
}

.jellieid_page .continue_btn:hover svg path {
  fill: #ffffff;
}

.jellieid_page .continue_btn svg {
  transform: rotate(270deg);
  float: right;
  margin: 8px 0;
}

.jellieid_page .continue_btn svg path {
  fill: #f5813c;
}

.jellieid_page .protect h5,
.jellieid_page .three-col .lost_pet h5,
.jellieid_page .three-col .easy_sharing h5,
.jellieid_page .three-col .privacy h5 {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #000000;
}

.jellieid_page .protect p,
.jellieid_page .three-col .lost_pet p,
.jellieid_page .three-col .easy_sharing p,
.jellieid_page .three-col .privacy p {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-top: 15px;
}

.jellieid_page .protect .pet_ss {
  width: 741px;
  height: 357px;
  margin-top: -20px;
}

.jellieid_page .three-col .lost_pet h5,
.jellieid_page .three-col .easy_sharing h5 {
  margin: 16px auto 45px;
  text-align: center !important;
}

.jellieid_page .three-col .privacy h5 {
  margin: 29px auto 45px;
  text-align: center !important;
}

.jellieid_page .three-col {
  margin-bottom: 0px;
}

@media only screen and (max-width: 992px) {
  .jellieid_page {
    margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: -50px;
  }

  .jellieid_page .protect .pet_ss {
    width: 720px;
    height: 337px;
    margin-top: -20px;
  }
}

@media only screen and (max-width: 768px) {
  .jellieid_page {
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: -50px;
  }

  .jellieid_page .type {
    display: block;
    margin-top: 20px;
  }

  .jellieid_page .protect h5 {
    margin-top: 40px;
  }

  .jellieid_page .protect p {
    margin-left: 10px;
  }

  .jellieid_page .protect .pet_ss {
    width: 327px;
    height: 157px;
    margin-top: 0px;
    text-align: center;
  }

  .jellieid_page .three-col {
    margin-top: 80px !important;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: -30px;
  }

  .jellieid_page .three-col .lost_pet p,
  .jellieid_page .three-col .easy_sharing p,
  .jellieid_page .three-col .privacy p {
    margin-bottom: 50px;
  }

  /* slider */
  .jellieid_page .type {
    display: flex;
    overflow-x: auto;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    margin: auto;
    justify-content: start;
    -webkit-overflow-scrolling: touch;
  }

  .jellieid_page .type .sticker,
  .jellieid_page .type .pendant {
    width: 300px;
    flex-shrink: 0;
    height: 100%;
  }

  .jellieid_page .type .sticker,
  .jellieid_page .type .pendant {
    width: 257px;
    height: 390px;
    text-align: center;
  }

  .jellieid_page .type .pendant .second_para {
    margin-left: 10px;
    margin-right: 10px;
  }
}

/* ---------------------mayur-------------------------------------------------------------------------------------- */
.webjellie_get_app .get_app {
  margin: 50px auto 66px;
}

@media (max-width: 768px) {
  .webjellie_get_app .get_app {
    margin: 33px auto 28px;
  }
}
