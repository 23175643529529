.help_content {
  width: 90%;
  margin-bottom: 57px; /*----------mayur------------------------------------------------------------------------ */
}

.help_content > h2 {
  font-weight: 700;
  color: #f5813c;
  line-height: 40px;
  margin: 100px auto 53px; /*----------mayur--------------------------------------------------------------------- */
}

.help_content > p {
  font-weight: 700;
}

.help_content ul {
  list-style: none;
  padding-left: 12px !important;
  margin-bottom: 70px;
}

.help_content ul li a {
  display: block;
  color: #4674c1;
}

.contactus_redirect p {
  font-weight: 700;
  margin: 60px auto 16px;
}

.contactus_redirect .btn {
  border: 2px solid #f5813c;
  color: #f5813c;
  border-radius: 10px;
  outline: none !important;
  background-color: #fff; /*----------mayur--------------------------------------------------------------------- */
}

.contactus_redirect .btn:hover,
.contactus_redirect .btn:active {
  background-color: #f5813c !important;
  border: 2px solid #f5813c !important;
  color: #fff;
}

@media (max-width: 768px) {
  .help_content {
    width: 100%;
    margin-top: -80px;
    margin-bottom: 49px; /*----------mayur------------------------------------------------------------------------- */
  }
}

/* ---------------------mayur-------------------------------------------------------------------------------------- */
.help_get_app .get_app {
  margin: 36px auto 43px;
}

.help_get_id .get_id {
  margin: 69px auto 115px;
}

@media (max-width: 768px) {
  .help_get_app .get_app {
    margin: 20px auto 49px;
  }

  .help_get_id .get_id {
    margin: -30px auto 20px; 
  }
}