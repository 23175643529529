.legal_content {
  width: 68%;
}

.legal_content > h2 {
  font-weight: 700;
  color: #f5813c;
  margin: 110px 0 50px;
}

.collapse_name {
  display: inline-flex;
  font-weight: 700;
  letter-spacing: 2%;
}

.legal svg {
  float: right;
  margin-top: 8px;
}

.legal svg path {
  fill: #acacac;
}

.is-closed > svg {
  transition: transform 0.3s ease-in-out;
}

.is-open > svg {
  transform: rotate(-180deg);
  transition: transform 0.3s ease-in-out;
}

.Collapsible__contentInner {
  margin: 16px 0 37px;
}

.profile_collapse_row_content {
  font-size: 14px;
}

.legal {
  padding-right: 100px;
  padding-left: 100px;
}

@media (max-width: 992px) {
  .legal {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media (max-width: 768px) {
  .legal_content {
    width: 100%;
    margin-bottom: 0; /* ---------------------mayur----------------------------------------------------------------- */
  }

  .legal_content > h2 {
    font-weight: 700;
    color: #f5813c;
    margin: 24px 0 50px;
  }
}

/* ---------------------mayur-------------------------------------------------------------------------------------- */
.legal_get_app .get_app {
  margin: 130px auto 43px;
}

.legal_get_id .get_id {
  margin: 64px auto 110px;
}

@media (max-width: 768px) {
  .legal_get_app .get_app {
    margin: 0 auto 13px;
  }

  .legal_get_id .get_id {
    margin: 0 auto 15px;
  }
}

@media (max-width: 540px) {
  tbody {
    font-size: 12px;
  }

  .table td {
    padding: 0.5rem;
  }
}
