.shippinginfo_content {
  width: 65%;
  margin: 110px auto 30px;
}

.shippinginfo_content h2 {
  font-weight: 700;
  color: #f5813c;
  margin: 18px 0;
}

.shippinginfo_content > p,
.shipping_payment > p {
  margin-bottom: 12px;
}

.shippinginfo_form {
}

.shippinginfo_formgroup {
  position: relative;
  margin: 0 !important;
}

.shippinginfo_formgroup-input {
  text-align: right;
  text-align: -webkit-right;
  /* direction: rtl; */
  appearance: none;
  font-size: 14px;
  color: #295e65;
  font-weight: bold;
}

.shippinginfo_formgroup-input2 {
  direction: rtl;
}

#recipientname {
  height: 46px;
  border-color: #e5e5e5;
  border-radius: 10px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

#recipientemail,
#address,
#city,
#state {
  height: 46px;
  border-top: none;
  border-bottom: none;
  border-color: #e5e5e5 !important;
  border-radius: 0 !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

#zip {
  height: 46px;
  border-top: none;
  border-color: #e5e5e5;
  border-radius: 10px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

#recipientname::placeholder,
#recipientemail::placeholder,
#address::placeholder,
#city::placeholder,
#state::placeholder,
#zip::placeholder {
  color: #acacac;
  font-size: 14px;
}

.shippinginfo_formgroup-label {
  position: absolute;
  top: 12px;
  left: 14px;
}

.shipping_amount_desc {
  margin: 51px 0 36px;
}

.shipping_amount {
  font-weight: 700;
}

.shipping_payment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shipping_payment > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment_options {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment_options a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 167px;
  height: 79px;
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.payment_options .paypal {
  margin-right: 12px;
}

.payment_options .paypal img {
  width: 51px;
  height: 49px;
}

.payment_options .applepay img {
  width: 78px;
  height: 42px;
}

.shipping_backstep {
  display: flex;
  margin: 28px 0;
}

.shipping_backstep a {
  text-align: center;
  text-decoration: underline;
  color: black;
  padding-right: 8px;
  margin: 0 auto;
}

/* .shipping_payment span {
  display: block;
    background-color: #fff;
    border: 1px solid #f5813c;
    border-radius: 10px;
  } */
.shipping_payment .pay_now {
  border-radius: 10px;
  margin-top: -1px;
  padding: 14px 26px;
  border: 1px solid #f5813c;
  outline: none !important;
  color: #f5813c;
  background-color: #fff;
}
.shipping_payment .pay_now:hover {
  background: #f5813c;
  border: 1px solid #fff;
  outline: none !important;
  color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .shippinginfo_content {
    width: 100%;
    margin: 26px auto 364px;
  }
}

@media only screen and (max-width: 520px) {
  .ship_to {
    display: none;
  }
  .shipping_payment {
    display: block;
  }

  .payment_options {
    justify-content: space-between;
  }

  .payment_options a {
    width: 186px;
    height: 84px;
  }
}

.dropdown_state {
  height: 46px;
  padding-right: 25px;
  border-top: none;
  border-bottom: none;
  border-color: #e5e5e5 !important;
  border-radius: 0 !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

.dropdown_state:focus {
  box-shadow: none;
  height: 46px;
  border-top: none;
  border-bottom: none;
  border-color: #e5e5e5;
  border-radius: 0 !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

.shipping_amount_description {
  margin: 24px 20px;
  text-align: end;
}

.loader-container { position: fixed; height: 100%; width: 100vw; top: 0; left: 0; background: rgba(255, 255, 255, 0.5); z-index: 111; }
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}