.contactus {
  padding-left: 140px;
  padding-right: 140px;
}

.contactus_info {
  width: 68%;
}

.contactus_info h2 {
  color: #f5813c;
  font-weight: 700;
  margin: 100px auto 49px;
}

.contactus_info p {
  color: black;
  font-weight: 700;
}

.contactus_form {
  margin-top: 50px;
}

.contactus_formgroup {
  position: relative;
  margin: 0 !important;
}

.contactus_formgroup-input {
  text-align: end;
  font-size: 14px;
  color: #295E65;
  font-weight: bold;
}

#name {
  height: 46px;
  border-color: #e5e5e5;
  border-bottom: 1px solid #e5e5e5 !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px 10px 0 0;
}

#email {
  height: 46px;
  border-top: none;
  border-color: #e5e5e5;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 10px 10px;
}

.contactus_textarea {
  margin-top: 25px;
}

#message {
  border-color: #e5e5e5;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  resize: none;
  border-radius: 10px;
}

#name::placeholder,
#email::placeholder,
#message::placeholder {
  color: #acacac;
  font-size: 14px;
}

.contactus_formgroup-label {
  position: absolute;
  top: 12px;
  left: 14px;
}

.contactus_form-button {
  height: 70px;
  width: 100%;
  color: #f5813c;
  border: 2px solid #f5813c;
  border-radius: 10px;
}

.contactus_form-button:hover,
.contactus_form-button:active {
  background-color: #f5813c !important;
  border-color: #f5813c !important;
}

@media (max-width: 992px) {
  .contactus {
    padding-right: 60px;
    padding-left: 60px;
  }
}

@media (max-width: 768px) {

  .contactus {
    padding-right: 15px;
    padding-left: 15px;
  }

  .contactus_info {
    width: 100%;
  }

  .contactus_info h2 {
    margin-top: 20px;
  }

}